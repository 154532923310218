<script setup lang="ts">
import { TabsContent, TabsList, TabsRoot, TabsTrigger } from 'radix-vue'
import { Navigation, Pagination } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { type PageBuilderTimelineListComponent } from '~/types/Page'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import WeImage from '~/components/WeImage.vue'
import type { Types } from '~/.directus/generated/client'

const props = defineProps<{
  items?: PageBuilderTimelineListComponent[]
}>()

const items = props.items
  ? await Promise.all(
      props.items.map(async (item) => {
        const imageFile = item.media?.[0]

        return {
          ...item,
          image: !!imageFile ? await transformFile(imageFile) : null,
        }
      })
    )
  : []

// Desktop: Tabs
const currentTabId = ref(items?.length ? String(items[0].id) : undefined)
const currentTabIndex = computed(() =>
  items?.findIndex(({ id }) => String(id) === currentTabId.value)
)

// Mobile: Slider
const modules = [Navigation, Pagination]
const prevEl = ref(null)
const nextEl = ref(null)
</script>

<style scoped>
.mobile-swiper {
  --swiper-pagination-progressbar-bg-color: theme('colors.ci.gray.500');
  --swiper-pagination-color: white;
  --swiper-pagination-progressbar-size: 2px;

  :deep(.swiper-pagination-progressbar) {
    top: theme(spacing.12);
  }
}
</style>

<template>
  <div>
    <section
      v-if="items?.length"
      class="bg-ci-violet-900 text-white selection-on-dark py-14 lg:py-28 xl:py-32"
    >
      <Container>
        <!-- Mobile -->
        <div class="relative lg:hidden">
          <!-- Controls -->
          <div class="flex gap-x-4 absolute z-10 top-0 right-0">
            <button
              ref="prevEl"
              class="block h-6 w-6 shrink-0 disabled:text-ci-gray-500 transition-colors duration-200"
            >
              <Icon name="arrow_back" />
              <span class="sr-only">{{ $t('previous') }}</span>
            </button>
            <button
              ref="nextEl"
              class="block h-6 w-6 shrink-0 disabled:text-ci-gray-500 transition-colors duration-200"
            >
              <Icon name="arrow_forward" />
              <span class="sr-only">{{ $t('next') }}</span>
            </button>
          </div>

          <!-- Slider -->
          <Swiper
            :slides-per-view="1"
            :space-between="32"
            :navigation="{ nextEl, prevEl }"
            :pagination="{
              type: 'progressbar',
            }"
            :modules="modules"
            class="mobile-swiper"
          >
            <SwiperSlide
              v-for="item in items"
              :key="item.id"
              class="!h-auto !min-h-full"
            >
              <!-- Tab headline -->
              <p class="text-26 leading-tight font-medium mb-12">
                {{ item.title_tab }}
              </p>

              <!-- Headline -->
              <h2
                v-if="item.title"
                class="text-35 leading-tight font-medium mb-2.5"
                v-html="pipe2nl(item.title)"
              ></h2>

              <!-- Text -->
              <div class="w-full md:w-8/12">
                <MarkdownRenderer
                  :source="item?.content || ''"
                  :options="{ breaks: true }"
                  class="text-22 leading-snug richtext [&_em]:text-ci-gray-500 [&_em]:not-italic"
                />
              </div>

              <!-- Image -->
              <div class="mt-8" v-if="item.image">
                <WeImage
                  :src="item.image.directus_files_id as Types.UUID"
                  :alt="item.image.alt"
                  quality="75"
                  :modifiers="{ fit: 'contain' }"
                />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>

        <!-- Desktop -->
        <TabsRoot class="hidden lg:block" v-model:model-value="currentTabId">
          <div class="border-b-2 border-ci-gray-500 pb-7 mb-20 relative">
            <TabsList class="flex gap-x-8 justify-between">
              <TabsTrigger
                v-for="item in items"
                class="text-35 leading-tight font-medium data-[state='inactive']:text-ci-gray-500 data-[state='active']:text-white hover:!text-white transition-colors"
                :value="`${item.id}`"
              >
                {{ item.title_tab }}
              </TabsTrigger>
            </TabsList>

            <!-- Current tab indicator -->
            <ClientOnly>
              <div
                class="absolute -bottom-0.5 left-0 right-0 h-0.5 bg-white transition-transform duration-300 origin-left scale-x-[--progress]"
                :style="{
                  '--progress':
                    items.length <= 1
                      ? 1
                      : ((currentTabIndex ?? 0) + 1) / items.length,
                }"
              ></div>
            </ClientOnly>
          </div>

          <TabsContent v-for="item in items" :value="`${item.id}`">
            <!-- Headline -->
            <h2
              v-if="item.title"
              class="text-35 lg:text-55 leading-tight font-medium mb-10 w-8/12"
              v-html="pipe2nl(item.title)"
            />

            <!-- Content -->
            <div class="flex space-between gap-x-32">
              <!-- Text -->
              <div class="w-1/2">
                <MarkdownRenderer
                  :source="item.content || ''"
                  :options="{ breaks: true }"
                  class="text-24 leading-snug richtext [&_h3]:text-35 [&_h3]:leading-tight [&_h3]:mb-[0.4em] [&_em]:text-ci-gray-500 [&_em]:not-italic"
                />
              </div>

              <!-- Image -->
              <div class="w-1/2 xl:shrink-0" v-if="item.image">
                <WeImage
                  :src="item.image.directus_files_id as Types.UUID"
                  :alt="item.image.alt"
                  quality="75"
                  :modifiers="{ fit: 'contain' }"
                />
              </div>
            </div>
          </TabsContent>
        </TabsRoot>
      </Container>
    </section>
  </div>
</template>
