<script setup lang="ts">
import { ref, onMounted, watch, computed } from 'vue'
import { useDepartmentsData } from '@/composables/useDepartmentsData'

const props = defineProps<{
  title: string
}>()

const defaultDepartment = { email: 'hello@welance.com' }

const formattedTitle = computed(() => pipe2nl(props.title))

const { data: departments, error, status, pending } = await useDepartmentsData()
const selectedDepartment = shallowRef<any>(defaultDepartment)

const onChange = (evt: any) => {
  const departmentId = parseInt(evt.target.value)
  const department = departments.value?.find(
    (department) => department.id === departmentId
  )
  if (department) {
    selectedDepartment.value = { ...department }
  }
}

onMounted(() => {
  if (
    !pending.value &&
    departments.value?.length &&
    departments.value?.length > 0
  ) {
    selectedDepartment.value = departments.value[0]
  }
})

const href = computed(() => {
  return `mailto:${selectedDepartment?.value.email || defaultDepartment.email}`
})

if (status.value === 'error') {
  throw error
}
</script>

<template>
  <section class="text-white bg-black selection-on-dark py-36">
    <Container>
      <!-- Headline -->
      <h2
        class="mb-12 font-medium leading-tight text-35 lg:text-55 md:w-10/12"
        v-html="formattedTitle"
      ></h2>

      <!-- CTA section -->
      <div class="flex flex-col items-end lg:flex-row gap-y-8 gap-x-32">
        <select
          class="w-full pb-5 font-medium leading-tight text-white bg-black border-b-2 border-white outline-none text-26 lg:text-35"
          @change="onChange"
        >
          <option
            v-for="department in departments"
            :key="department.id"
            :style="{ color: department.color_main as string }"
            :value="department.id"
          >
            {{ department.currentTranslation?.title }}
          </option>
        </select>

        <div class="shrink-0">
          <WeButton variant="ghost" color="light" :href="href">
            {{ $t('discussProject') }}
          </WeButton>
        </div>
      </div>
    </Container>
  </section>
</template>
