<script setup lang="ts">
defineProps<{
  title?: string
  content?: string
}>()

const { data: departments, error, status } = await useDepartmentsData()

// Fetch error
if (status.value === 'error') {
  throw error
}

const currentDepartmentId = useState<number | null>(
  'currentDepartmentId',
  () => null
)
const currentDepartment = computed(() =>
  currentDepartmentId.value && departments.value !== null
    ? departments.value.find(({ id }) => id === currentDepartmentId.value)
    : null
)

const departmentTo = computed(() => {
  return 'focus/' + currentDepartment.value?.currentTranslation?.slug
})

const visualRotation = computed(() => {
  let rotation = -90

  const departmentIndex = departments.value?.findIndex(
    ({ id }) => id === currentDepartmentId.value
  )
  if (typeof departmentIndex !== 'undefined' && departmentIndex >= 0) {
    rotation = rotation + departmentIndex * 45
  }

  return `${rotation}deg`
})
</script>

<template>
  <section
    class="bg-ci-gray-100 py-14 lg:pt-28 lg:pb-40 transition-colors duration-300 relative z-0 overflow-hidden"
    :style="{ backgroundColor: currentDepartment?.color_main as string }"
    @mouseleave="currentDepartmentId = null"
  >
    <Container class="relative z-10">
      <!-- Headline -->
      <div class="mb-10 lg:mb-28 border-b-2 border-black pb-5 lg:pb-7">
        <h2 class="text-26 lg:text-35 leading-tight font-medium">
          {{ title }}
        </h2>
      </div>

      <!-- Departments -->
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-y-8 gap-x-20">
        <!-- Selection -->
        <ul
          class="text-35 lg:text-55 leading-tight font-medium space-y-2 lg:space-y-5"
        >
          <li v-for="department in departments" :key="department.id">
            <!-- Mobile link -->
            <button
              class="inline-block lg:hidden text-start transition-opacity duration-300"
              :class="{
                'opacity-20':
                  currentDepartmentId !== null &&
                  currentDepartmentId !== department.id,
              }"
              @click="currentDepartmentId = department.id"
            >
              <span>{{ department.currentTranslation?.title }}</span>
            </button>

            <!-- Desktop link -->
            <NuxtLinkLocale
              class="hidden lg:inline-block text-start transition-opacity duration-300"
              :class="{
                'opacity-20':
                  currentDepartmentId !== null &&
                  currentDepartmentId !== department.id,
              }"
              :to="`/focus/${department.currentTranslation?.slug}`"
              @mouseover="currentDepartmentId = department.id"
            >
              <span>{{ department.currentTranslation?.title }}</span
              ><span class="whitespace-nowrap">
                <div
                  :class="{
                    'opacity-100': currentDepartmentId === department.id,
                  }"
                  aria-hidden="true"
                  class="hidden lg:inline opacity-0 text-black transition-opacity duration-300 ml-4"
                >
                  <Icon name="arrow_forward" x-large weight="500" />
                </div>
              </span>
            </NuxtLinkLocale>
          </li>
        </ul>

        <!-- Content -->
        <div class="w-full md:w-8/12 lg:w-auto">
          <MarkdownRenderer
            :source="
              currentDepartment?.currentTranslation?.short_description ??
              content ??
              ''
            "
            :options="{ breaks: true }"
            class="text-22 lg:text-28 leading-normal richtext"
          />

          <!-- More button (mobile) -->
          <WeButton
            class="mt-8 lg:hidden"
            variant="ghost"
            :to="`/focus/${currentDepartment?.currentTranslation?.slug}`"
            v-show="currentDepartment"
          >
            {{ currentDepartment?.currentTranslation?.title }} ➤
          </WeButton>
        </div>
      </div>
    </Container>

    <!-- Visual -->
    <div
      class="opacity-0 bg-white/20 pointer-events-none absolute -right-36 lg:right-0 top-32 h-[120px] w-[540px] lg:h-[200px] lg:w-[900px] rotate-[var(--rotation)] transition duration-300"
      :class="{
        'opacity-100': currentDepartmentId !== null,
      }"
      :style="{
        '--rotation': visualRotation,
      }"
    ></div>
  </section>
</template>
