export const useCommunityGalleryData = async () => {
  const { $directus, $readSingleton } = useNuxtApp()
  const { localeProperties } = useI18n()

  const langIso = localeProperties.value.iso || ''

  return useAsyncData(`${langIso}/community_gallery`, () =>
    $directus.request(
      $readSingleton('projects_info', {
        fields: [
          'id',
          {
            community_gallery: ['id', 'directus_files_id'],
          },
        ],
      })
    )
  )
}
