import { ref, onMounted, onUnmounted, watch } from 'vue'

export function useMedia(query: string) {
  const matches = ref(false)

  const getMatches = (query: string): boolean => {
    if (typeof window !== 'undefined') {
      return window.matchMedia(query).matches
    }
    return false
  }

  const handleChange = () => {
    matches.value = getMatches(query)
  }

  onMounted(() => {
    const matchMedia = window.matchMedia(query)

    handleChange()

    if (matchMedia.addEventListener) {
      matchMedia.addEventListener('change', handleChange)
    } else {
      matchMedia.addListener(handleChange)
    }

    onUnmounted(() => {
      if (matchMedia.removeEventListener) {
        matchMedia.removeEventListener('change', handleChange)
      } else {
        matchMedia.removeListener(handleChange)
      }
    })
  })

  watch(() => query, handleChange)

  return matches
}
