<template>
  <section
    class="relative overflow-hidden"
    :class="{
      'bg-ci-gray-100': !backgroundColor,
      'text-white selection-on-dark': !darkText,
    }"
    :style="{
      backgroundColor: backgroundColor as string,
    }"
  >
    <Container>
      <div
        class="flex py-14 gap-y-16 xl:gap-y-0 xl:py-24"
        :class="{
          'justify-end flex-col ': template === 'bw-text-left-bottom',
          'justify-end flex-col-reverse lg:relative lg:!pb-40':
            template === 'bw-text-left-top',
          'pt-[160px] xl:pt-[500px]': media && isImage(media.metadata.type),
          'pt-[340px] xl:pt-[500px]':
            media &&
            isJson(media.metadata.type) &&
            template === 'bw-text-left-bottom',
        }"
      >
        <!-- Media IMAGE and position of the media on top of the text on mobile -->
        <div
          v-if="media && isImage(media.metadata.type)"
          class="absolute -top-[25%] -right-[35%] md:-top-[27%] md:-right-[23%] lg:-top-[45%] lg:-right-[50%] xl:-top-[55%] xl:-right-[35%] 2xl:-right-[20%] 2xl:-top-[55%]"
        >
          <WeImage
            :src="media.directus_files_id as Types.UUID"
            quality="90"
            :modifiers="{ fit: 'contain' }"
            v-if="isImage(media.metadata.type)"
            :alt="media.alt"
            :sizes="'350px md:450px lg:1200px'"
            class="h-[350px] w-[350px] md:h-[450px] md:w-[450px] lg:h-[1200px] lg:w-[1200px] opacity-25"
          />
        </div>
        <!-- Media JSON and position of the media on bottom of the text on mobile -->
        <div
          v-if="
            media &&
            isJson(media.metadata.type) &&
            template === 'bw-text-left-top'
          "
          class="-mt-8 -mr-16 md:absolute md:bottom-0 md:w-3/5 md:right-0 md:mb-10 lg:w-1/2 lg:mb-14 text-white/75 [&_path]:stroke-current [&_path]:fill-current"
        >
          <LottieWrapper
            :src="media.mediaUrl"
            autoplay
            loop
            :speed="1"
            renderer="svg"
          />
        </div>
        <!-- Media JSON and position of the media on top of the text on mobile -->
        <div
          v-if="
            media &&
            isJson(media.metadata.type) &&
            template === 'bw-text-left-bottom'
          "
          class="absolute -top-[170px] -right-[200px] md:-top-[28%] md:-right-[32%] lg:-right-[22%] xl:-top-[15%] xl:-right-[5%] h-[570px] w-[570px] md:h-[720px] md:w-[720px] xl:h-[850px] xl:w-[850px]"
        >
          <LottieWrapper
            :src="media.mediaUrl"
            autoplay
            :speed="1"
            renderer="svg"
          />
        </div>
        <!-- Content -->
        <div
          :class="{
            '': !!media && template === 'bw-text-left-bottom',
            'xl:-mb-12':
              !!media &&
              isImage(media.metadata.type) &&
              template === 'bw-text-left-top',
            'z-10': !!media,
          }"
        >
          <h1
            class="text-50 leading-[60px] font-medium mb-[0.5em] xl:text-85 xl:leading-[102px]"
          >
            {{ title }}
          </h1>
          <MarkdownRenderer
            v-if="content"
            :source="content"
            :options="{ breaks: true }"
            :inline="true"
            tag="h2"
            class="w-full leading-snug text-24 lg:text-28 md:w-8/12 richtext"
          />
        </div>
      </div>
    </Container>
  </section>
</template>

<script setup lang="ts">
import { type PageBuilderIntroComponent } from '~/types/Page'
import LottieWrapper from '@/components/LottieWrapper.vue'
import WeImage from '~/components/WeImage.vue'
import type { Types } from '~/.directus/generated/client'

const props = withDefaults(
  defineProps<{
    template?: PageBuilderIntroComponent['template']
    title?: PageBuilderIntroComponent['title']
    content?: PageBuilderIntroComponent['content']
    media?: PageBuilderIntroComponent['background_media']
    backgroundColor?: PageBuilderIntroComponent['background_color']
  }>(),
  { template: 'bw-text-left-top' }
)

const media = !!props.media?.[0] ? await transformFile(props.media[0]) : null
const content = props.content || ''
const darkText = props.backgroundColor
  ? isDarkOnBgColor(props.backgroundColor)
  : true
</script>
