export const XL_SCREEN = 1280
export const LG_SCREEN = 1024
export const MD_SCREEN = 768
export const SM_SCREEN = 640
export const XS_SCREEN = 480

export function useIsMobile(maxWidth: number = MD_SCREEN) {
  return useMedia(`(max-width: ${maxWidth}px)`)
}

export function useIsTablet(
  minWidth: number = MD_SCREEN,
  maxWidth: number = LG_SCREEN
) {
  return useMedia(
    `(min-width: ${minWidth + 1}px) and (max-width: ${maxWidth - 1}px)`
  )
}

export function useIsDesktop(minWidth: number = LG_SCREEN) {
  return useMedia(`(min-width: ${minWidth}px)`)
}

export function useIsLgScreen(
  minWidth: number = LG_SCREEN,
  maxWidth: number = XL_SCREEN
) {
  return useMedia(
    `(min-width: ${minWidth}px) and (max-width: ${maxWidth - 1}px)`
  )
}

export function useIsXlScreen(minWidth: number = XL_SCREEN) {
  return useMedia(`(min-width: ${minWidth}px)`)
}
