<script setup lang="ts">
import { register } from 'swiper/element/bundle'
import {
  type PageBuilderPeopleGPSComponent,
  type PageBuilderPeopleGPSComponentItem,
} from '~/types/Page'
import type { Collections, Types } from '~/.directus/generated/client'
import type { CTA } from '~/types/CTA'
import { Autoplay, Virtual } from 'swiper/modules'

register()

const props = defineProps<{
  title?: string
  content?: string
  includes?: PageBuilderPeopleGPSComponent['includes']
}>()

const formattedTitle = computed(() => pipe2nl(props.title))

// CTAs
const ctas = props.includes?.flatMap(({ item: cta }) =>
  cta
    ? [
        {
          ...(cta as PageBuilderPeopleGPSComponentItem),
          currentTranslation: (cta as PageBuilderPeopleGPSComponentItem)
            ?.translations?.[0],
        },
      ]
    : []
)

// Slides
const { data, error, status } = await useCommunityGalleryData()
// Fetch error
if (status.value === 'error') {
  throw error
}
const slides = await transformFilesList(
  data.value?.community_gallery as Collections.ProjectsInfoFiles[]
)
const activeIndex = ref(0)
const onActiveIndexChange = (event: any) => {
  const [swiper] = event.detail
  activeIndex.value = swiper.realIndex
}
const geoLocation = computed(() => {
  return slides[activeIndex.value || 0].metadata.location
})
const isMobile = useIsMobile()
</script>

<template>
  <section class="py-14 lg:py-28">
    <!-- Headline -->
    <Container>
      <h2
        class="font-medium leading-tight text-35 lg:text-55"
        v-html="formattedTitle"
      ></h2>
    </Container>

    <!-- Slider -->
    <div class="mt-10 lg:mt-24" v-if="slides?.length">
      <ClientOnly>
        <swiper-container
          :modules="[Autoplay, Virtual]"
          :slides-per-view="isMobile ? 2 : 4"
          :space-between="32"
          :speed="900"
          centered-slides
          slideToClickedSlide
          :loop="true"
          :autoplay="{
            delay: 1500,
            waitForTransition: true,
          }"
          @swiperactiveindexchange="onActiveIndexChange"
          virtual
        >
          <swiper-slide
            v-for="slide in slides"
            :key="slide.id"
            class="w-60 md:w-72 lg:w-[300px] h-auto flex items-center"
          >
            <WeImage
              :src="slide.directus_files_id as Types.UUID"
              :alt="slide.alt"
              quality="75"
              :modifiers="{
                fit: 'contain',
              }"
            />
          </swiper-slide>
        </swiper-container>
      </ClientOnly>
    </div>

    <!-- Content -->
    <Container class="mt-10 lg:mt-32">
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-y-8 gap-x-20">
        <!-- Coordinates -->
        <p class="font-medium leading-normal text-18 lg:text-24">
          {{ geoLocation }}
        </p>

        <!-- Text -->
        <div>
          <MarkdownRenderer
            v-if="content"
            :source="content"
            :options="{ breaks: true }"
            class="w-full leading-normal richtext text-24 lg:text-28 md:w-8/12 lg:w-auto"
          />

          <!-- CTAs -->
          <div
            class="flex flex-col items-start mt-4 lg:mt-8 gap-y-4"
            v-if="ctas?.length"
          >
            <WeButton
              v-for="cta in ctas"
              :key="cta.id"
              variant="ghost"
              v-bind="resolveCTA(cta as CTA)"
            >
              <template #default="{ data }">
                {{ data?.title }}
              </template>
            </WeButton>
          </div>
        </div>
      </div>
    </Container>
  </section>
</template>
