<script setup lang="ts">
const runtimeConfig = useRuntimeConfig()
import { type Collections } from '~/.directus/generated/client'

const props = defineProps<{
  builder: Collections.PagesBuilder[]
}>()

const pageBuilderItems = usePageBuilder(props.builder)
</script>

<template>
  <div>
    <template v-for="[item, props] in pageBuilderItems" :key="item.id">
      <component :is="item" v-bind="props" />
    </template>
  </div>
</template>
