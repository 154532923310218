import { STATUS } from '~/types'

export const useJobData = async () => {
  const { $directus, $readItems } = useNuxtApp()
  return useAsyncData('jobs', () =>
    $directus.request(
      $readItems('Jobs', {
        fields: [
          'id',
          'title',
          'full_description',
          'date_created',
          { updated_by: ['fullName', { profile_img: ['*'] }] },
        ],
        filter: {
          status: {
            _eq: STATUS.PUBLISHED,
          },
        },
        sort: ['-date_created'],
        limit: 50,
      })
    )
  )
}
