import {
  PBBlockDefault,
  PBBlockTimeline,
  PBCmpCTAList,
  PBCmpDefault,
  PBCmpDepartments,
  PBCmpDepartmentsSmall,
  PBCmpDepartmentsSmallCenter,
  PBCmpIntro,
  PBCmpScrollyTelling,
  PBCmpText,
  PBCmpJobChat,
  PBCmpPeopleGPS,
} from '#components'
import type { Collections } from '~/.directus/generated/client'

export const usePageBuilder = (builder?: Collections.PagesBuilder[]) =>
  builder
    ?.map((item) =>
      item.collection === 'Components'
        ? componentRouter(
            (item?.item as Collections.Components)?.translations[0]
          )
        : blockRouter(item.item as Collections.Blocks)
    )
    .map(([cmp, props], index, array) => [
      cmp,
      {
        ...props,
        prev: (array[index - 1]?.[1] as any)?.type,
        next: (array[index + 1]?.[1] as any)?.type,
      },
    ])

const componentRouter = (component?: Collections.ComponentsTranslations) => {
  if (!component) {
    return [PBCmpDefault, {}]
  }

  if (component.type === 'intro') {
    return [
      PBCmpIntro,
      {
        type: component.type,
        template: component.template,
        title: component.title,
        content: component.content,
        media: component.background_media,
        backgroundColor: component.background_color,
      },
    ]
  } else if (component.type === 'list') {
    if (component.template === 'welance-scrolly-animation') {
      return [
        PBCmpScrollyTelling,
        {
          type: component.type,
          content: component.content,
          media: component.media,
        },
      ]
    } else if (component.template === 'people-gps') {
      return [
        PBCmpPeopleGPS,
        {
          type: component.type,
          title: component.title,
          content: component.content,
          includes: component.includes,
        },
      ]
    } else if (component.template === 'departments-dropdown-cta') {
      return [PBCmpCTAList, { type: component.type, title: component.title }]
    } else if (component.template === 'departments-small-left') {
      return [
        PBCmpDepartmentsSmall,
        {
          type: component.type,
          title: component.title,
          content: component.content,
        },
      ]
    } else if (component.template === 'departments-small-center') {
      return [
        PBCmpDepartmentsSmallCenter,
        {
          type: component.type,
          title: component.title,
          content: component.content,
        },
      ]
    } else if (component.template === 'welance-job-chat') {
      return [PBCmpJobChat]
    } else if (component.template === 'departments-big-left') {
      return [
        PBCmpDepartments,
        {
          type: component.type,
          title: component.title,
          content: component.content,
        },
      ]
    }
  } else if (component.type === 'text') {
    return [
      PBCmpText,
      {
        type: component.type,
        title: component.title,
        content: component.content,
        template: component.template,
      },
    ]
  }

  return [PBCmpDefault, { component }]
}

const blockRouter = (block?: Collections.Blocks) => {
  if (!block) {
    return [PBBlockDefault, {}]
  }

  if (block.type === 'timeline') {
    if (
      block.Components.every((component) => {
        const translation = (component?.item as Collections.Components)
          .translations?.[0]
        return (
          translation &&
          translation.type === 'list' &&
          translation.template === 'timeline-dark'
        )
      })
    ) {
      return [
        PBBlockTimeline,
        {
          items: block.Components.map(
            ({ item }) => (item as Collections.Components)?.translations?.[0]
          ),
        },
      ]
    }
  }

  return [PBBlockDefault, { block }]
}
