<script setup lang="ts">
import { type PageBuilderScrollyAnimComponent } from '~/types/Page'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import SplitType from 'split-type'
import lottie from 'lottie-web'

gsap.registerPlugin(ScrollTrigger)

const runtimeConfig = useRuntimeConfig()

const props = defineProps<{
  content?: string
  media?: PageBuilderScrollyAnimComponent['media']
}>()

const media = props.media?.[0]
const mediaURL = media
  ? `${runtimeConfig.public.cmsUrl}assets/${media.directus_files_id}`
  : null

// Split content into two parts at the first full stop
const splitContent = (content: string) => {
  const firstDotIndex = content.indexOf('.')
  if (firstDotIndex === -1) return [content, '']
  return [content.slice(0, firstDotIndex + 1), content.slice(firstDotIndex + 1)]
}

const firstPart = ref('')
const secondPart = ref('')

if (props.content) {
  const [first, second] = splitContent(props.content)
  firstPart.value = first
  secondPart.value = second
}

// helper function for animation
function LottieScrollTrigger(vars) {
  let playhead = { frame: vars.startFrameOffset || 0 },
    target = gsap.utils.toArray(vars.target)[0],
    speeds = { slow: '+=2000', medium: '+=1000', fast: '+=500' },
    st = {
      trigger: target,
      start: 'top top',
      end: speeds[vars.speed] || '+=1000',
      scrub: 1,
    },
    ctx = gsap.context && gsap.context(),
    animation = lottie.loadAnimation({
      container: target,
      renderer: vars.renderer || 'svg',
      loop: false,
      autoplay: false,
      path: vars.path,
      rendererSettings: vars.rendererSettings || {
        preserveAspectRatio: 'xMidYMid slice',
      },
    }),
    frameAnimation
  for (let p in vars) {
    // let users override the ScrollTrigger defaults
    st[p] = vars[p]
  }
  frameAnimation = vars.timeline || gsap.timeline({ scrollTrigger: st })
  if (vars.timeline && !vars.timeline.vars.scrollTrigger) {
    // if the user passed in a timeline that didn't have a ScrollTrigger attached, create one.
    st.animation = frameAnimation
    ScrollTrigger.create(st)
  }
  animation.addEventListener('DOMLoaded', function () {
    let createTween = function () {
      animation.goToAndStop(playhead.frame, true)
      frameAnimation.to(
        playhead,
        {
          frame: animation.totalFrames - 1 - (vars.endFrameOffset || 0),
          ease: 'none',
          duration: frameAnimation.duration() || 1,
          onUpdate: () => {
            animation.goToAndStop(playhead.frame, true)
          },
        },
        0
      )
      return () => animation.destroy && animation.destroy()
    }
    ctx && ctx.add ? ctx.add(createTween) : createTween()
    // in case there are any other ScrollTriggers on the page and the loading of this Lottie asset caused layout changes
    ScrollTrigger.sort()
    ScrollTrigger.refresh()
  })
  animation.frameAnimation = frameAnimation
  return animation
}

let scrollTriggerInstance: ScrollTrigger | null = null

onMounted(() => {
  const tl = gsap.timeline({
    defaults: {
      ease: 'none',
    },
    scrollTrigger: {
      trigger: '.pinned-section',
      start: 'top 3%',
      end: '+=300%', // this is slowing down the animation by 300%
      scrub: true,
      pin: 'main',
    },
  })

  const headlineElement = document.querySelector('.js-headline') as HTMLElement
  const subheadlineElement = document.querySelector(
    '.js-subheadline'
  ) as HTMLElement

  const textHeadline = new SplitType(headlineElement, {
    types: 'words, chars',
    preserveWords: true,
  })
  const textSubHeadline = new SplitType(subheadlineElement, {
    types: 'words, chars',
    preserveWords: true,
  })

  tl.to(textHeadline.chars, {
    duration: 11,
    // This value (duration) is set based on the 300% end of the scrolltrigger,
    // the total seconds of the Lottie animation is 6 seconds (18seconds total)
    // we want to end the first sentence chars animation at about 63% of the total animation
    // which is the frame of the lottie animation when the sticks "melt" together, forming 4 different teams.
    color: 'black',
    stagger: 0.15, // delay for animation of single chars
    ease: 'steps(1)',
  }).to(textSubHeadline.chars, {
    color: 'black',
    stagger: 0.15, // delay for animation of single chars
    ease: 'steps(1)',
  })

  LottieScrollTrigger({
    timeline: tl,
    target: '#animationWindow',
    path: mediaURL,
    speed: 'slow',
    scrub: 1,
  })

  if (scrollTriggerInstance) {
    scrollTriggerInstance.kill()
    scrollTriggerInstance = null
  }

  // Store the instance to kill it later
  scrollTriggerInstance = ScrollTrigger.create({
    trigger: '.pinned-section',
  })

  onBeforeUnmount(() => {
    // Kill the ScrollTrigger instance
    if (scrollTriggerInstance) {
      scrollTriggerInstance.kill()
      scrollTriggerInstance = null
    }
    // Clear all ScrollTrigger instances
    ScrollTrigger.getAll().forEach((trigger) => trigger.kill())
  })
})
</script>

<template>
  <section
    class="relative flex flex-col justify-center md:flex-row md:flex-wrap pinned-section"
  >
    <div class="relative z-10 px-5 py-12 sm:px-12 md:pt-20 lg:px-20">
      <div class="relative h-full mx-auto max-w-7xl pinned-inner">
        <!-- Content -->
        <div
          class="font-medium leading-tight richtext text-[3.9svh] sm:text-40 lg:text-55 md:w-10/12 text-ci-gray-200"
        >
          <div v-if="firstPart" class="js-headline">
            <MarkdownRenderer :source="firstPart" :inline="true" tag="span" />
          </div>
          <div v-if="secondPart" class="js-subheadline">
            <MarkdownRenderer :source="secondPart" :inline="true" tag="span" />
          </div>
        </div>
        <!-- Visual -->
        <div
          class="max-w-sm mt-5 ml-auto pointer-events-none sm:max-w-md md:mt-0 lg:-mt-32 md:max-w-lg lg:w-1/2 lg:max-w-2xl"
        >
          <div id="animationWindow"></div>
        </div>
      </div>
    </div>
  </section>
</template>
