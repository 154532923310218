export function blendColors(baseColor: string, opacity: number): string {
  // Convert base color and white to RGBA
  const baseColorRGBA = hexToRGBA(baseColor)
  const whiteColorRGBA: RGBA = { r: 255, g: 255, b: 255, a: 1 }

  // Blend colors
  const blendedColor = blendRGBAColors(baseColorRGBA, whiteColorRGBA, opacity)

  // Convert blended color back to hex
  const blendedColorHex = rgbaToHex(blendedColor)

  return blendedColorHex
}

interface RGBA {
  r: number
  g: number
  b: number
  a: number
}

// Convert hex to RGBA
export function hexToRGBA(hex: string): RGBA {
  const bigint = parseInt(hex.slice(1), 16)
  return {
    r: (bigint >> 16) & 255,
    g: (bigint >> 8) & 255,
    b: bigint & 255,
    a: 1, // Set alpha to 1 for the base color
  }
}

// Blend two RGBA colors based on opacity
export function blendRGBAColors(
  color1: RGBA,
  color2: RGBA,
  opacity: number
): RGBA {
  const blendedColor: RGBA = {
    r: Math.round(color1.r * (1 - opacity) + color2.r * opacity),
    g: Math.round(color1.g * (1 - opacity) + color2.g * opacity),
    b: Math.round(color1.b * (1 - opacity) + color2.b * opacity),
    a: 1, // Set alpha to 1 for the resulting color
  }

  return blendedColor
}

// Convert RGBA to hex
export function rgbaToHex(rgba: RGBA): string {
  const { r, g, b } = rgba
  const hex = `#${((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1)}`
  return hex
}

// Decide foreground color based on background color
// True if dark foreground
// False if light foreground
export function isDarkOnBgColor(bgColor: string): boolean {
  const color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor
  const r = parseInt(color.substring(0, 2), 16) // hexToR
  const g = parseInt(color.substring(2, 4), 16) // hexToG
  const b = parseInt(color.substring(4, 6), 16) // hexToB
  const uicolors = [r / 255, g / 255, b / 255]
  const c = uicolors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92
    }
    return Math.pow((col + 0.055) / 1.055, 2.4)
  })
  const L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2]
  return L > 0.179
}
