<script setup lang="ts">
defineProps<{
  title?: string
  content?: string
}>()

const { data: departments, error, status } = await useDepartmentsData()

// Fetch error
if (status.value === 'error') {
  throw error
}

const currentDepartmentId = useState<number | null>(
  'currentDepartmentId',
  () => null
)
const currentDepartment = computed(() =>
  currentDepartmentId.value && departments.value !== null
    ? departments.value.find(({ id }) => id === currentDepartmentId.value)
    : null
)
</script>

<template>
  <section
    class="bg-ci-gray-100 py-14 lg:pt-28 lg:pb-40 transition-colors duration-300 relative z-0 overflow-hidden"
    :style="{
      backgroundColor: currentDepartment?.color_main
        ? blendColors(currentDepartment.color_main, 0.9)
        : undefined,
    }"
    @mouseleave="currentDepartmentId = null"
  >
    <!-- Content -->
    <Container class="w-full md:w-8/12 mx-auto text-center relative z-10">
      <!-- Headline -->
      <div class="mb-5 lg:mb-20 md:w-6/12 mx-auto">
        <h2 class="text-26 lg:text-35 leading-tight font-medium mb-[1.5em]">
          {{ title }}
        </h2>
      </div>

      <!-- Departments -->
      <div class="space-y-2">
        <!-- Selection -->
        <ul
          class="text-26 lg:text-35 leading-tight font-medium space-y-3 lg:space-y-4"
        >
          <li v-for="department in departments">
            <!-- Mobile link -->
            <NuxtLinkLocale
              class="inline-block lg:hidden text-start transition-opacity duration-300 underline-offset-[.125em]"
              :class="{
                underline: currentDepartmentId === department.id,
              }"
              :style="{ color: department.color_main }"
              :to="`/focus/${department.currentTranslation?.slug}`"
            >
              <span>{{ department.currentTranslation?.title }}</span>
            </NuxtLinkLocale>

            <!-- Desktop link -->
            <NuxtLinkLocale
              class="hidden lg:inline-block text-start transition-opacity duration-300 underline-offset-[.125em]"
              :class="{
                underline: currentDepartmentId === department.id,
              }"
              :style="{ color: department.color_main }"
              :to="`/focus/${department.currentTranslation?.slug}`"
              @mouseover="currentDepartmentId = department.id"
            >
              <span>{{ department.currentTranslation?.title }}</span>
            </NuxtLinkLocale>
          </li>
        </ul>
      </div>
    </Container>
  </section>
</template>
