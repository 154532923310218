<script setup lang="ts">
import { Collapse } from 'vue-collapsed'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import isToday from 'dayjs/plugin/isToday'
import isYesterday from 'dayjs/plugin/isYesterday'
import type { Collections, Types } from '~/.directus/generated/client'
import WeImage from '~/components/WeImage.vue'
dayjs.extend(advancedFormat)
dayjs.extend(isToday)
dayjs.extend(isYesterday)

const { toggle } = useChatWoot()
const { data: jobs } = await useJobData()
const isExpanded = ref(false)
const formatDate = computed(() => (date: Types.Optional<Types.DateTime>) => {
  const dateObject = dayjs(date)
  if (dateObject.isToday()) {
    return 'Today'
  } else if (dateObject.isYesterday()) {
    return 'Yesterday'
  }
  return dayjs(date).format('MMMM Do')
})

const isEmpty = computed(() => {
  return jobs.value && jobs.value.length === 0
})
const showMoreBtn = computed(() => {
  return jobs.value && jobs.value.length >= 4
})
function handleCollapse() {
  isExpanded.value = !isExpanded.value
}
</script>

<template>
  <div
    class="flex flex-col py-12 mx-5 mb-24 space-y-8 max-w-7xl lg:py-24 xl:mx-auto md:mx-12 lg:mx-20"
  >
    <h2 class="font-medium text-35 lg:text-55">welance gigs and jobs</h2>
    <div
      v-if="!isEmpty"
      class="relative after:absolute after:inset-x-0 after:-bottom-1 after:bg-gradient-to-t after:from-white after:to-transparent after:content-['']"
      :class="{
        'after:h-0': isExpanded,
        'after:h-2/3': showMoreBtn && !isExpanded,
      }"
    >
      <Collapse :when="isExpanded" :baseHeight="400">
        <div class="grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
          <div
            v-for="job in jobs"
            :key="job.id"
            class="flex flex-col p-8 font-medium text-black rounded-md bg-[#F9F9F9] justify-between"
          >
            <div class="flex flex-col space-y-2">
              <div class="text-16">
                {{ formatDate(job.date_created) }}
              </div>
              <div
                class="overflow-hidden font-medium text-22 lg:text-24 text-ellipsis"
              >
                {{ job.full_description }}
              </div>
            </div>
            <div
              class="flex gap-2.5 items-center mt-6 text-xl leading-7 whitespace-nowrap"
            >
              <WeImage
                :src="
                  (job.updated_by?.profile_img as Collections.DirectusFile)?.id
                "
                quality="50"
                :sizes="'42px'"
                :modifiers="{ fit: 'contain', saturation: 0 }"
                class="shrink-0 self-stretch rounded-full aspect-square w-[42px] h-[42px] overflow-hidden"
              />
              <div class="self-stretch flex-1 my-auto">
                {{ job.updated_by?.fullName }}
              </div>
              <div
                class="cursor-pointer hover:text-black/70"
                @click="toggle(undefined)"
              >
                <Icon name="chat_bubble" />
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="!isExpanded && showMoreBtn"
          class="absolute z-10 -translate-x-1/2 left-1/2 -bottom-20 lg:-bottom-32 whitespace-nowrap"
          @click="handleCollapse()"
        >
          <WeButton variant="ghost">{{ $t('older-messages') }}</WeButton>
        </div>
      </Collapse>
    </div>
    <div class="p-8 text-center bg-gray-100 rounded-md" v-else>
      <h3 class="mt-2 font-semibold text-gray-900 text-22">
        {{ $t('sorry') }}
      </h3>
      <i18n-t keypath="noJobPosting">
        <!-- Slot for mail link -->

        <template #break>
          <br />
        </template>
        <template #mailLink>
          <a href="mailto:hello@welance.com" class="text-blue-500 underline"
            >hello@welance.com</a
          >
        </template>

        <!-- Slot for chat link -->
        <template #chatLink>
          <span
            class="text-blue-500 underline cursor-pointer"
            @click="toggle(undefined)"
            >chat message (bottom-left corner)</span
          >
        </template>
      </i18n-t>
    </div>
  </div>
</template>
