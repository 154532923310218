<script setup lang="ts">
defineProps<{
  title?: string
  content?: string
}>()

const { data: departments, error, status } = await useDepartmentsData()

// Fetch error
if (status.value === 'error') {
  throw error
}

const currentDepartmentId = useState<number | null>(
  'currentDepartmentId',
  () => null
)
const currentDepartment = computed(() =>
  currentDepartmentId.value && departments.value !== null
    ? departments.value.find(({ id }) => id === currentDepartmentId.value)
    : null
)
</script>

<template>
  <section
    class="relative z-0 overflow-hidden transition-colors duration-300 bg-ci-gray-100 py-14 lg:pt-28 lg:pb-40"
    :style="{
      backgroundColor: currentDepartment?.color_main
        ? blendColors(currentDepartment.color_main, 0.9)
        : undefined,
    }"
    @mouseleave="currentDepartmentId = null"
  >
    <!-- Content -->
    <Container class="relative z-10">
      <!-- Headline -->
      <div class="mb-5 lg:mb-20 md:w-8/12">
        <h2 class="font-medium leading-tight text-35 lg:text-55">
          {{ title }}
        </h2>
      </div>

      <!-- Departments -->
      <div class="grid grid-cols-1 lg:grid-cols-2 gap-y-10 gap-x-20">
        <!-- Selection -->
        <ul
          class="space-y-3 font-medium leading-tight text-26 lg:text-35 lg:space-y-7"
        >
          <li v-for="department in departments" :key="department.id">
            <!-- Mobile link -->
            <button
              class="inline-block lg:hidden text-start transition-opacity duration-300 underline-offset-[.125em]"
              :class="{
                underline: currentDepartmentId === department.id,
              }"
              :style="{ color: department.color_main as string }"
              @click="currentDepartmentId = department.id"
            >
              <span>{{ department.currentTranslation?.title }}</span>
            </button>

            <!-- Desktop link -->
            <NuxtLinkLocale
              class="hidden lg:inline-block text-start transition-opacity duration-300 underline-offset-[.125em]"
              :class="{
                underline: currentDepartmentId === department.id,
              }"
              :style="{ color: department.color_main }"
              :to="`/focus/${department.currentTranslation?.slug}`"
              @mouseover="currentDepartmentId = department.id"
            >
              <span>{{ department.currentTranslation?.title }}</span>
            </NuxtLinkLocale>
          </li>
        </ul>

        <!-- Content -->
        <div class="w-full md:w-8/12 lg:w-auto">
          <div
            v-if="!currentDepartment"
            class="items-start hidden h-full font-medium leading-snug lg:flex text-22 lg:text-24"
          >
            {{ $t('weSpecialize') }}
          </div>
          <MarkdownRenderer
            :source="
              currentDepartment?.currentTranslation?.short_description ??
              content ??
              ''
            "
            :options="{ breaks: true }"
            class="font-medium leading-snug text-22 lg:text-24 richtext"
          />

          <!-- E-Mail button -->
          <WeButton
            class="mt-8"
            variant="ghost"
            :to="`mailto:${currentDepartment?.email}`"
            v-if="currentDepartment?.email"
          >
            {{ currentDepartment.email }}
          </WeButton>
        </div>
      </div>
    </Container>
  </section>
</template>
